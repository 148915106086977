import { ProfileSummaryType } from '~/endpoints/model'
import { Flex, Modal, Textarea, createStyles } from '@mantine/core'
import { Typography } from '~/components/shared/text/Typography'
import { useScreenSize } from '~/hooks'
import Image from 'next/image'
import { getImageUrl } from '../shared/image/helpers'
import { Title, TitleItalic } from '../shared/text/Title'
import { useState } from 'react'
import { ThatchButton } from '../shared/ThatchButton'
import { SvgIcon, SvgIconType } from '../shared/image/SvgIcon'
import { useAnalytics, useCurrentAuthState } from '~/context'

const useStyle = createStyles({
    areaInput: {
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: 8,
    },
    areaInputText: {
        padding: 16,
        borderRadius: 8,
    },
})

export function MessageSellerModal({ isOpened, onClose, profile }: { isOpened: boolean, onClose: () => void, profile: ProfileSummaryType }) {
    const { isMobileScreen } = useScreenSize()
    const { classes } = useStyle()
    const [message, setMessage] = useState('')
    const [sent, setSent] = useState(false)
    const thatchSegment = useAnalytics()
    const thatchUser = useCurrentAuthState()

    const sendMessage = () => {
        const evt = {
            seller: {
                firstName: profile.firstName,
                lastName: profile.lastName,
                username: profile.username,
                uid: profile.uid,
                email: profile.email,
            },
            message: message,
            user: {
                email: thatchUser.email,
                uid: thatchUser?.userProfile?.uid,
                username: thatchUser?.userProfile?.username,
                firstName: thatchUser?.userProfile?.firstName,
                lastName: thatchUser?.userProfile?.lastName,
            }
        }
        console.log('send message', JSON.stringify(evt))
        thatchSegment.trackMiscEvent('send_seller_message', evt)
        setSent(true)
    }


    return (
        <Modal
            opened={isOpened}
            onClose={onClose}
            fullScreen={isMobileScreen}
            radius={isMobileScreen ? 0 : 16}
            centered
        >
            <Flex direction="column" gap={40} p={32} mt={-60} justify={sent ? 'center' : 'flex-start'}>
                {!sent && (<>
                    <div>
                        <div className="flex gap-3 items-center">
                            <div className="rounded-[50%] overflow-hidden">
                                <Image
                                    alt="seller-photo"
                                    quality={100}
                                    src={getImageUrl({ src: profile.photo }) || ''}
                                    width={58}
                                    height={58}
                                />
                            </div>

                            <div className="flex flex-col gap-1">
                                <Title variant='titleSerif'>Send me a <TitleItalic>message</TitleItalic></Title>
                                <Typography variant='caption' color='appPlaceholder.0'>I usually respond within a few hours</Typography>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col gap-4'>
                        <Title variant="body2Serif">Your <TitleItalic>Message</TitleItalic><span className='text-app-success'>*</span></Title>
                        <Textarea classNames={{ root: classes.areaInput, input: classes.areaInputText }}
                            sx={{ width: '100%' }}

                            minRows={2}
                            maxLength={1000}
                            autosize placeholder="Type your message..." value={message} onChange={(e) => setMessage(e.currentTarget.value)} />
                    </div>

                    <ThatchButton label='Send Message' color='appSuccess.0' h={48} onClick={sendMessage} />
                </>)}

                {sent && (<>
                    <div className='flex flex-col gap-6 items-center pt-10'>
                        <SvgIcon type={SvgIconType.BIG_CHECKED_CIRCLE} width={54} height={54} fill='appSuccess.0' />
                        <Title variant='titleSerif'>Message <TitleItalic>Sent</TitleItalic></Title>
                    </div>
                    <div className='flex gap-4 justify-center'>
                        <ThatchButton label='Done' size='small' onClick={onClose} color='appBlack.0' className='px-12' />
                    </div>
                </>)}

                {/* <MessageSellerForm profile={profile} /> */}
            </Flex>
        </Modal>
    )
}