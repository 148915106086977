import { useUser } from 'next-firebase-auth'
import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { notify } from '~/components/shared/notify'
import { fetchProfileSummary } from '~/endpoints/user'

export const useProfielSummary = ({ username, failSilently }: { username: string, failSilently?: boolean }) => {
  const authUser = useUser()

  const { data: profileSummary, isLoading, error } = useQuery({
    queryKey: ['profileSummary', username],
    queryFn: async () => {
      const data = await fetchProfileSummary(`@${username}`, authUser?.getIdToken)
      return data
    },
    enabled: !!username
  })

  useEffect(() => {
    if (error && !failSilently) {
      notify(true, 'Failed to fetch user information')
    }
  })

  return {
    profileSummary,
    isLoading,
  }
}

export const useProfielSummaryAnon = ({ username, failSilently }: { username: string, failSilently?: boolean }) => {
  const { data: profileSummary, isLoading, error } = useQuery({
    queryKey: ['profileSummaryAnon', username],
    queryFn: async () => {
      const data = await fetchProfileSummary(`@${username}`, undefined)
      return data
    },
    enabled: !!username
  })

  useEffect(() => {
    if (error && !failSilently) {
      notify(true, 'Failed to fetch user information')
    }
  }, [error, failSilently])

  return {
    profileSummary,
    isLoading,
  }
}
