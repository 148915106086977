import { Box, Flex, Stack, createStyles, Menu } from '@mantine/core'
import { CSSProperties } from 'react'
import { ProfileServicesMenuItems } from '~/components/seller/HireMeButton'
import { ThatchAvatar } from '~/components/shared/ThatchAvatarDynamic'
import { ThatchLink } from '~/components/shared/ThatchLink'
import { getImageUrl } from '~/components/shared/image/helpers'
import { Typography, Variant } from '~/components/shared/text/Typography'
import { ProfileBasicSummaryType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'
import { useProfielSummaryAnon } from '~/components/seller/hooks/useProfileSummary'
// eslint-disable-next-line import/no-cycle
import { AvailableForHireTag } from '../seller/AvailableForHireTag'
import { useAnalytics, useCurrentAuthState } from '~/context'
import { IconChevronRight } from '@tabler/icons'
import { useMobileOS } from '~/hooks/useMobileOS'
import { useState, useEffect } from 'react';
import { InlineAuthView } from '../auth/InlineAuthView'
import { MessageSellerModal } from '../seller/MessageSellerModal'

interface BoardAuthorWithoutContextProps {
    author: ProfileBasicSummaryType
    desktopPhotoSize?: number
    mobilePhotoSize?: number
    photoGap?: number
    titleStyle?: CSSProperties
    containerStyle?: CSSProperties
    titleVariant?: Variant
    tagVariant?: Variant
    showAvailableForHireTag?: boolean
    hideLabelPhoto?: boolean
    handleGuideClick?: any
    click_source?: string
}

const useStyle = createStyles(_theme => ({
    container: {
        margin: '20px 0 24px 0',
    },
}))

export const BoardAuthorWithoutContext: React.FC<BoardAuthorWithoutContextProps> = ({
    author,
    desktopPhotoSize = 40,
    mobilePhotoSize = 40,
    photoGap = 12,
    titleStyle = {},
    containerStyle = {},
    titleVariant = 'body2SerifItalic',
    tagVariant,
    showAvailableForHireTag = false,
    hideLabelPhoto,
    handleGuideClick,
    click_source
}) => {
    const { classes } = useStyle()
    const { isMobileScreen } = useScreenSize()
    const { isMobileOS } = useMobileOS()
    const thatchSegment = useAnalytics()
    const { hireable, name, photo, username, uid } = author
    const { profileSummary, isLoading } = useProfielSummaryAnon({ username: author.username, failSilently: true })
    const [open, setOpen] = useState(false)
    const [showMessageSellerModal, setShowMessageSellerModal] = useState<boolean>(false)
    const [showInlineAuth, setShowInlineAuth] = useState<boolean>(false)
    const { email } = useCurrentAuthState()

    function didClickMessageMe() {
        console.log('clicked_profile_services_dropdown_item')
        thatchSegment.trackMiscEvent('clicked_profile_services_dropdown_item', {
            item: 'message_me',
            source: 'hire_me_button',
            username: profileSummary?.username,
            token: profileSummary?.uid,
            name: profileSummary?.name,
        })
        if (!email) {
            setShowInlineAuth(true)
        } else {
            setShowMessageSellerModal(true)
        }
    }

    const onUserSetupSuccess = async (_currentUserUid: string) => {
        // await performAction()
        setShowInlineAuth(false)
        setShowMessageSellerModal(true)
    }
    
    useEffect(() => {
        if (open) {
            console.log('avatar_menu_opened')
            thatchSegment.trackMiscEvent('avatar_menu_opened', {
                offers_services: hireable,
                token: uid,
                username,
                display_name: name,
            })
        }
    }, [open])

    function didClick(e: any) {
        thatchSegment.trackMiscEvent('avatar_clicked', {
            offers_services: hireable,
            token: uid,
            username,
            display_name: name,
            path: window.location.pathname,
            click_source
        });
    }

    const photoSize = isMobileScreen ? mobilePhotoSize : desktopPhotoSize

    if (!author.hireable) {
        return (<ThatchLink
            to={`/@${username}`}
            isOpenInNewWindow
            underline={false}
            onClick={didClick}
            className='cursor-pointer'
        >
            <BoardAuthorWithoutContextInner author={author} desktopPhotoSize={desktopPhotoSize} mobilePhotoSize={mobilePhotoSize} photoGap={photoGap} titleStyle={titleStyle} containerStyle={containerStyle} titleVariant={titleVariant} tagVariant={tagVariant} showAvailableForHireTag={showAvailableForHireTag} hideLabelPhoto={hideLabelPhoto} handleGuideClick={handleGuideClick} click_source={click_source} />
        </ThatchLink>)
    }

    return (<>
        <Menu shadow='md' width={320} withinPortal={true} trigger={isMobileOS ? 'click' : 'hover'} openDelay={10} closeDelay={100} position='bottom-start' offset={-1 * (photoSize + 40)} opened={open} onChange={setOpen}>
            <Menu.Target>
                <a className='hover:no-underline cursor-pointer'>
                    <BoardAuthorWithoutContextInner author={author} desktopPhotoSize={desktopPhotoSize} mobilePhotoSize={mobilePhotoSize} photoGap={photoGap} titleStyle={titleStyle} containerStyle={containerStyle} titleVariant={titleVariant} tagVariant={tagVariant} showAvailableForHireTag={showAvailableForHireTag} hideLabelPhoto={hideLabelPhoto} handleGuideClick={handleGuideClick} click_source={click_source} />
                </a>
            </Menu.Target>
            <Menu.Dropdown className='rounded-lg'>
                <ThatchLink
                    to={`/@${username}`}
                    isOpenInNewWindow
                    underline={false}
                    onClick={didClick}
                    className='cursor-pointer'
                >
                    <Flex px={8} align='center' justify='space-between' className='hover:bg-app-paper rounded'>
                        <BoardAuthorWithoutContextInner author={author} containerStyle={{ margin: '16px 0px' }} />
                        <IconChevronRight size={20} color="black" />
                    </Flex>
                </ThatchLink>
                {profileSummary && profileSummary.hireable &&
                    <>
                        <Menu.Divider />
                        {profileSummary &&
                            <ProfileServicesMenuItems profileDetails={profileSummary} source='avatar' didClickMessageMe={didClickMessageMe}/>
                        }
                    </>
                }
            </Menu.Dropdown>
        </Menu>
        {showInlineAuth && (
            <InlineAuthView
                launchSource="message_me"
                onClose={() => setShowInlineAuth(false)}
                isOpened={showInlineAuth}
                initLandingView="signup"
                onEmailAuthSuccess={onUserSetupSuccess}
            />
        )}
        {showMessageSellerModal && profileSummary && (
            <MessageSellerModal
                isOpened={showMessageSellerModal}
                onClose={() => setShowMessageSellerModal(false)}
                profile={profileSummary}
            />
        )}
    </>)
}

export const BoardAuthorWithoutContextInner: React.FC<BoardAuthorWithoutContextProps> = ({
    author,
    desktopPhotoSize = 40,
    mobilePhotoSize = 40,
    photoGap = 12,
    titleStyle = {},
    containerStyle = {},
    titleVariant = 'body2SerifItalic',
    tagVariant,
    showAvailableForHireTag = false,
    hideLabelPhoto,
    handleGuideClick,
    click_source
}) => {
    const { classes } = useStyle()
    const { isMobileScreen } = useScreenSize()
    const thatchSegment = useAnalytics()
    const { hireable, name, photo, username, uid } = author
    const { profileSummary, isLoading } = useProfielSummaryAnon({ username: author.username, failSilently: true })

    return (<Box
        className={classes.container}
        style={containerStyle}
    >
        <Box
        // tabIndex={-1}
        // onClick={handleGuideClick}
        >
            <Flex
                gap={photoGap}
                align="center"
            >
                {!hideLabelPhoto && photo && (
                    <ThatchAvatar
                        url={getImageUrl({
                            src: photo,
                            width: isMobileScreen ? mobilePhotoSize : desktopPhotoSize,
                        })}
                        desktopSize={desktopPhotoSize}
                        mobileSize={mobilePhotoSize}
                    />
                )}
                <Stack
                    sx={{ flexDirection: 'column' }}
                    spacing={3}
                >
                    <Typography
                        span
                        variant={titleVariant}
                        style={titleStyle}
                        lineClamp={1}
                        sx={{ fontSize: '16px ' }}
                        className='pr-1 pb-0.5'
                    >
                        {name}
                    </Typography>

                    {(showAvailableForHireTag || hireable) && (
                        <AvailableForHireTag variant={tagVariant} />
                    )}
                </Stack>
            </Flex>
        </Box>
    </Box>)
}
