import { ThatchButton } from '~/components/shared/ThatchButton'
import { Menu, Flex } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons'
import { Typography } from '../shared/text/Typography';
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { ProfileSummaryType } from '~/endpoints/model'
import { useAnalytics, useCurrentAuthState } from '~/context';
import { InlineAuthView } from '../auth/InlineAuthView';
import { useState } from 'react';
import { MessageSellerModal } from './MessageSellerModal';

export function HireMeButton({ profileDetails, label, buttonClass }: { profileDetails?: ProfileSummaryType, label?: string, buttonClass?: string }) {
    const thatchSegment = useAnalytics()

    const [showInlineAuth, setShowInlineAuth] = useState<boolean>(false)
    const [showMessageSellerModal, setShowMessageSellerModal] = useState<boolean>(false)
    const { email } = useCurrentAuthState()

    function didClickMessageMe() {
        console.log('clicked_profile_services_dropdown_item')
        thatchSegment.trackMiscEvent('clicked_profile_services_dropdown_item', {
            item: 'message_me',
            source: 'hire_me_button',
            username: profileDetails?.username,
            token: profileDetails?.uid,
            name: profileDetails?.name,
        })
        if (!email) {
            setShowInlineAuth(true)
        } else {
            setShowMessageSellerModal(true)
        }
    }

    const onUserSetupSuccess = async (_currentUserUid: string) => {
        // await performAction()
        setShowInlineAuth(false)
        setShowMessageSellerModal(true)
    }

    const didOpenMenu = () => {
        console.log('clicked_hire_me_button')
        thatchSegment.trackMiscEvent('clicked_hire_me_button', {})
    }

    return (<>
        <Menu shadow='md' width={320} position='bottom-start'>
            <Menu.Target>
                <ThatchButton label={
                    <Flex align="center" gap={4} className='px-2 grow shrink md:px-2'>
                        <Typography variant="button_small" color="appWhite.0">{label ?? "Contact Me"}</Typography>
                        <IconChevronDown size={20} color="white" />
                    </Flex>
                } color="appSuccess.0" size="medium" onClick={didOpenMenu} sx={{
                    '@media (max-width: 768px)': {
                        flexGrow: 1,
                        paddingLeft: 4,
                        paddingRight: 4,
                        // width: '100%'
                    }
                }} className={buttonClass}/>
            </Menu.Target>
            <Menu.Dropdown className='rounded-lg'>
                <ProfileServicesMenuItems profileDetails={profileDetails} source='hire_me_button' didClickMessageMe={didClickMessageMe} />
            </Menu.Dropdown>
        </Menu>
        {showInlineAuth && (
            <InlineAuthView
                launchSource="message_me"
                onClose={() => setShowInlineAuth(false)}
                isOpened={showInlineAuth}
                initLandingView="signup"
                onEmailAuthSuccess={onUserSetupSuccess}
            />
        )}
        {showMessageSellerModal && profileDetails && (
            <MessageSellerModal
                isOpened={showMessageSellerModal}
                onClose={() => setShowMessageSellerModal(false)}
                profile={profileDetails}
            />
        )}
    </>);
}

export function ProfileServicesMenuItems({ profileDetails, source, didClickMessageMe }: { profileDetails?: ProfileSummaryType, source: string, didClickMessageMe: () => void }) {
    const thatchSegment = useAnalytics()

    function didClickItem(item: string) {
        thatchSegment.trackMiscEvent('clicked_profile_services_dropdown_item', {
            item,
            source,
            username: profileDetails?.username,
            token: profileDetails?.uid,
            name: profileDetails?.name,
        })
    }
    return (<>
        <Menu.Item component='a' className='hover:bg-app-paper hover:no-underline' onClick={didClickMessageMe}>
            <Flex align="center" gap={12}>
                <div className='rounded-full bg-app-paper p-1.5'>
                    <SvgIcon type={SvgIconType.ENVELOPE} width={16} height={16} />
                </div>
                <Typography variant="body3" color="appBlack.0">Send me a message</Typography>
                <div className='h-1 grow' />
                <IconChevronRight size={20} color="black" />
            </Flex>
        </Menu.Item>
        {profileDetails?.consultation && !profileDetails.consultation.disabled && (
            <Menu.Item component='a' href={`/seller/services/consultation/@${profileDetails.username}`} target='_blank' className='hover:bg-app-paper hover:no-underline' onClick={() => didClickItem('consultation')}>
                <Flex align="center" gap={12}>
                    <div className='rounded-full bg-app-paper p-1.5'>
                        <SvgIcon type={SvgIconType.CONSULTATION_ICON} width={20} height={20} />
                    </div>
                    <Typography variant="body3" color="appBlack.0">Chat with Me</Typography>
                    <div className='h-1 grow' />
                    <IconChevronRight size={20} color="black" />
                </Flex>
            </Menu.Item>
        )}
        {profileDetails?.itinerary && !profileDetails.itinerary.disabled && (
            <Menu.Item component='a' href={`/seller/services/itinerary/@${profileDetails.username}`} target='_blank' className='hover:bg-app-paper hover:no-underline' onClick={() => didClickItem('itinerary')}>
                <Flex align="center" gap={12}>
                    <div className='rounded-full bg-app-paper p-1.5'>
                        <SvgIcon type={SvgIconType.ITINERARY_ICON} width={20} height={20} />
                    </div>
                    <Typography variant="body3" color="appBlack.0">{"I'll plan your trip"}</Typography>
                    <div className='h-1 grow' />
                    <IconChevronRight size={20} color="black" />
                </Flex>
            </Menu.Item>
        )}
        {profileDetails?.recs && !profileDetails.recs.disabled && (
            <Menu.Item component='a' href={`/seller/services/recs/@${profileDetails.username}`} target='_blank' className='hover:bg-app-paper hover:no-underline' onClick={() => didClickItem('recs')}>
                <Flex align="center" gap={12}>
                    <div className='rounded-full bg-app-paper p-1.5'>
                        <SvgIcon type={SvgIconType.RECS_SERVICE_ICON} width={20} height={20} />
                    </div>
                    <Typography variant="body3" color="appBlack.0">{"I’ll make a list of recs for you "}</Typography>
                    <div className='h-1 grow' />
                    <IconChevronRight size={20} color="black" />
                </Flex>
            </Menu.Item>
        )}
    </>)
}